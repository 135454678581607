@import "https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,500;0,800;1,400&family=Raleway:wght@100;200;400;700&family=Unica+One&display=swap";
* {
  box-sizing: border-box;
  color: #565254;
}

body {
  color: #565254;
  background-color: #fffbfe;
  margin: 0;
  font-family: Raleway, sans-serif;
}

.header {
  text-align: center;
  grid-area: header;
  padding: 30px;
  font-family: Raleway, sans-serif;
  font-size: 35px;
}

.navbar {
  background-color: #d0cfcf;
  border-radius: 5px;
  grid-area: navbar;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px;
  display: -ms-flexbox;
  display: flex;
}

.navbar div:hover {
  background: #fff;
}

.navbarbutton, a:link, a:visited {
  height: 40px;
  width: 25%;
  text-align: center;
  border-radius: 5px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
}

.grid-container {
  grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
  grid-template-areas: "header header header header header header header header"
                       "left navbar navbar navbar navbar navbar navbar right"
                       "left middle middle middle middle middle middle right"
                       "footer footer footer footer footer footer footer footer";
  justify-content: space-evenly;
  display: grid;
}

.left, .middle, .right {
  padding: 10px;
}

.left {
  grid-area: left;
}

.middle {
  color: #7a7d7d;
  text-align: center;
  grid-area: middle;
  font-family: Bodoni Moda, serif;
}

.right {
  grid-area: right;
}

.footer {
  text-align: center;
  grid-area: footer;
  padding: 10px;
  font-family: Unica One, cursive;
}

@media (max-width: 800px) {
  .grid-container {
    grid-template-areas: "header header header header header header header header"
                         "navbar navbar navbar navbar navbar navbar navbar navbar"
                         "left left left left left left left left"
                         "middle middle middle middle middle middle middle middle"
                         "right right right right right right right right"
                         "footer footer footer footer footer footer footer footer";
  }

  .navbar {
    border-radius: 0;
  }
}

@media (max-width: 400px) {
  .navbar {
    display: inline;
  }

  .navbarbutton {
    width: 100%;
  }
}

/*# sourceMappingURL=index.99421651.css.map */
