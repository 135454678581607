/* SCSS HEX */
$snow: #fffbfeff;
$gray-web: #7a7d7dff;
$light-gray: #d0cfcfff;
$davys-gray: #565254ff;
$white: #ffffffff;

/* SCSS HSL */
$snow: hsla(315, 100%, 99%, 1);
$gray-web: hsla(180, 1%, 48%, 1);
$light-gray: hsla(0, 1%, 81%, 1);
$davys-gray: hsla(330, 2%, 33%, 1);
$white: hsla(0, 0%, 100%, 1);

/* SCSS RGB */
$snow: rgba(255, 251, 254, 1);
$gray-web: rgba(122, 125, 125, 1);
$light-gray: rgba(208, 207, 207, 1);
$davys-gray: rgba(86, 82, 84, 1);
$white: rgba(255, 255, 255, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-right: linear-gradient(90deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-bottom: linear-gradient(180deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-left: linear-gradient(270deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-top-right: linear-gradient(45deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-bottom-right: linear-gradient(135deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-top-left: linear-gradient(225deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-bottom-left: linear-gradient(315deg, #fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);
$gradient-radial: radial-gradient(#fffbfeff, #7a7d7dff, #d0cfcfff, #565254ff, #ffffffff);


// font-family: 'Bodoni Moda', serif;
// font-family: 'Raleway', sans-serif;
// font-family: 'Unica One', cursive;
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,500;0,800;1,400&family=Raleway:wght@100;200;400;700&family=Unica+One&display=swap');

* {
    box-sizing: border-box;
    color: $davys-gray;
  }

  body {
    background-color: $snow;
    color: $davys-gray;
    margin: 0px;
    font-family: 'Raleway', sans-serif;
  }
  
  /* Style the header */
  .header {
    grid-area: header;
    padding: 30px;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    font-size: 35px;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    grid-area: navbar;
    padding: 5px;
    background-color: $light-gray;
    border-radius: 5px;
  }

  .navbar div:hover {
      background: $white;
  }

  .navbarbutton, a:link, a:visited {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 25%;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    border-radius: 5px;
  }

  /* The grid container */
  .grid-container {
    display: grid;
    grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
    justify-content: space-evenly;
    grid-template-areas: 
      'header header header header header header header header' 
      'left navbar navbar navbar navbar navbar navbar right' 
      'left middle middle middle middle middle middle right' 
      'footer footer footer footer footer footer footer footer';
    /* grid-column-gap: 10px; - if you want gap between the columns */
  } 
  
  .left,
  .middle,
  .right {
    padding: 10px;
  }
  
  /* Style the left column */
  .left {
    grid-area: left;
  }
  
  /* Style the middle column */
  .middle {
    grid-area: middle;
    color: $gray-web;
    font-family: 'Bodoni Moda', serif;
    text-align: center;
  }
  
  /* Style the right column */
  .right {
    grid-area: right;
  }
  
  /* Style the footer */
  .footer {
    grid-area: footer;
    padding: 10px;
    font-family: 'Unica One', cursive;
    text-align: center;
  }
  
  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
  @media (max-width: 800px) {
    .grid-container  {
      grid-template-areas: 
        'header header header header header header header header' 
        'navbar navbar navbar navbar navbar navbar navbar navbar'
        'left left left left left left left left' 
        'middle middle middle middle middle middle middle middle' 
        'right right right right right right right right' 
        'footer footer footer footer footer footer footer footer';
    }

    .navbar {
      border-radius: 0px;
    }
  }

  @media (max-width: 400px) {
    .navbar {
      display: inline;
    }
  
    .navbarbutton {
      width: 100%;
    }
  }